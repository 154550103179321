import React from 'react'
import { CSVLink } from 'react-csv'
import Button from '../Buttons'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import SearchBox from './SearchBox'

const DataTableHeading = ({
    exportable,
    searchable,
    data,
    preGlobalFilteredRows,
    state,
    setGlobalFilter,
    tableHeading,
}) => {
    const generatePDF = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#table' })
        doc.save('report.pdf')
    }
    return (
        <div className="text-right flex justify-between item-center">
            <div className="flex justify-start items-center">
                <h4 className="pl-4">{tableHeading}</h4>
            </div>
            {exportable && (
                <div>
                    <Button>
                        <CSVLink data={data}> Export as CSV</CSVLink>
                    </Button>

                    <Button className="ml-2" onClick={generatePDF}>
                        Export as PDF
                    </Button>
                </div>
            )}
            {searchable && (
                <div className="flex justify-start items-center">
                    <SearchBox
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter}
                    />
                </div>
            )}
        </div>
    )
}

export default DataTableHeading
