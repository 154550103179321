import React, { useRef } from "react";
import { Button, Dropdown } from "components/ui";

const ActionDropdown = React.forwardRef(({ indeterminate, onChange, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    const Toggle = <Button size="sm">Options</Button>;

    return (
        <Dropdown renderTitle={Toggle} trigger="hover" className="mr-2 z-[100]" ref={resolvedRef} onChange={(_, e) => onChange(e)}>
            <Dropdown.Item eventKey="a" onClick={() => console.log(rest.firstName)}>
                Edit
            </Dropdown.Item>
            <Dropdown.Item eventKey="b">Delete</Dropdown.Item>
        </Dropdown>
    );
});

export default ActionDropdown;
