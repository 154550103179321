import { createSlice } from "@reduxjs/toolkit"

export const initialState = {
    name: '',
    website: '',
    email: '',
    address: '',
    city: '',
    state: '',
    phone: '',
    display_name: '',
    id: ''
}

const organizationSlice = createSlice({
    name: 'auth/organization',
    initialState,
    reducers: {
        setOrganization: (_, action) => action.payload,
        organizationLogout: () => initialState,
    },
})

export const { setOrganization,organizationLogout } = organizationSlice.actions

export default organizationSlice.reducer
