import React from 'react'
import Pagination from '../Pagination'
import Select from '../Select'

const pageSizeOption = [
    //{ value: 5, label: '5 / page' },
    { value: 10, label: '10 / page' },
    { value: 20, label: '20 / page' },
    { value: 30, label: '30 / page' },
    { value: 40, label: '40 / page' },
    { value: 50, label: '50 / page' },
]

const DataTablePagination = ({
    pageSize,
    pageIndex,
    dataLength,
    gotoPage,
    setPageSize,
}) => {
    const onPaginationChange = (page) => {
        gotoPage(page - 1)
    }

    const onSelectChange = (value) => {
        setPageSize(Number(value))
    }

    // Custom styles for the dropdown
    // const customStyles = {
    //     menu: (provided) => ({
    //         ...provided,
    //         maxHeight: '110px', 
    //         overflowY: 'auto', 
    //     }),
    //     control: (provided) => ({
    //         ...provided,
    //         minWidth: '130px',
    //     }),
    // }


    // Custom styles for this specific Select component
    //    const customSelectStyles = {
    //     menu: (provided) => ({
    //         ...provided,
    //         maxHeight: '106px', 
    //         overflowY: 'auto',
    //         zIndex: 50,
    //         position: 'absolute', 
    //         top: '100%', 
    //         left: 0,
    //     }),
    //     menuList: (provided) => ({
    //         ...provided,
    //         padding: 0,
    //         maxHeight: '102px',
    //         overflowY: 'auto',
    //         scrollBehavior: 'smooth',
    //     }),
    //    }

       const customSelectStyles = {
            menuList: (provided) => ({
                ...provided,
                padding: 0,
                maxHeight: '102px',
                overflowY: 'auto',
                scrollBehavior: 'smooth',
            }),
        }


    return (
        <div className="flex items-center justify-between mt-4">
            <Pagination
                pageSize={pageSize}
                currentPage={pageIndex + 1}
                total={dataLength}
                onChange={onPaginationChange}
            />
            <div>
                <b>Total Record: {dataLength}</b>
            </div>
            <div style={{ minWidth: 130 }}>
                <Select
                    // styles={customStyles}
                    customStyles={customSelectStyles}
                    size="sm"
                    isSearchable={false}
                    value={pageSizeOption.filter(
                        (option) => option.value === pageSize
                    )}
                    options={pageSizeOption}
                    onChange={(option) => onSelectChange(option.value)}
                />
            </div>
        </div>
    )
}

export default DataTablePagination
