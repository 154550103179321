import React from 'react'
import TBody from '../Table/TBody'
import Td from '../Table/Td'
import Tr from '../Table/Tr'

const DataTBody = ({ getTableBodyProps, page, prepareRow, allColumns }) => {
    return (
        <TBody {...getTableBodyProps()}>
            {page.map((row, i) => {
                prepareRow(row)
                return (
                    <Tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                            return (
                                <Td {...cell.getCellProps()}>
                                    {/* {console.log(cell)} */}
                                    {cell.render('Cell')}
                                </Td>
                            )
                        })}
                    </Tr>
                )
            })}

            {page.length === 0 && (
                <Tr>
                    <Td className="text-center" colSpan={allColumns.length}>
                        No data found!
                    </Td>
                </Tr>
            )}
        </TBody>
    )
}

export default DataTBody
