import { Skeleton } from "components/ui";
import { useState, useRef, useEffect } from "react";

const ScrollPad = ({ scrollContainerRef }) => {
  const [isDragging, setIsDragging] = useState(false);
  const startPosition = useRef({ x: 0 });
  const scrollPosition = useRef({ left: 0 });

  const onMouseDown = (e) => {
    e.preventDefault(); // Prevents text selection while dragging
    setIsDragging(true);
    startPosition.current = {
      x: e.pageX,
    };
    scrollPosition.current = {
      left: scrollContainerRef.current.scrollLeft,
    };
  };

  const onMouseMove = (e) => {
    if (!isDragging) return;
    const xDiff = e.pageX - startPosition.current.x;

    scrollContainerRef.current.scrollLeft = scrollPosition.current.left - xDiff;
  };

  const onMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", onMouseMove);
      window.addEventListener("mouseup", onMouseUp);
    } else {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, [isDragging]);

  return (
    <div
      className={`w-32 h-12 bg-gray-200 rounded-md cursor-grab transition-transform flex flex-col opacity-95
      duration-200 ${isDragging ? "scale-105 shadow-lg cursor-grabbing" : ""}`}
      onMouseDown={onMouseDown}
    >
      <div className=" w-full h-full flex flex-row justify-around items-center">
      <Skeleton height={40} width={20}  className="  bg-gray-300" />
      <Skeleton height={40} width={20} className="  bg-gray-300" />
      <Skeleton height={40} width={20} className="  bg-gray-300" />
      <Skeleton height={40} width={20} className="  bg-gray-300" />
      <Skeleton height={40} width={20} className="  bg-gray-300" />
      </div>

    </div>
  );
};

export default ScrollPad;