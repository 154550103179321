const apiPrefix = process.env.REACT_APP_BACKEND_URL
const appConfig = {
    apiPrefix:apiPrefix,
    authenticatedEntryPath: '/dashboard',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
}

export default appConfig
