import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store'
import Theme from 'components/template/Theme'
import Layout from 'components/layout'
import history from './history'
import mockServer from './mock'
import appConfig from 'configs/app.config'
import { Auth0Provider } from '@auth0/auth0-react'
import './locales'
import { ScrollBar } from 'components/ui'

const environment = process.env.NODE_ENV
const domain = process.env.REACT_APP_DOMAIN
const clientId = process.env.REACT_APP_CLIENT_ID
const audience = process.env.REACT_APP_AUDENCE

/**
 * Set enableMock(Default false) to true at configs/app.config.js
 * If you wish to enable mock api
 */
// testing
if (environment !== 'production' && appConfig.enableMock) {
    mockServer({ environment })
}
// console.log(domain,clientId,audience);

function App() {
    return (
        <ScrollBar
            autoHide={true}
            autoHideTimeout={1000}
            autoHideDuration={400}
            style={{ width: "100vw", height: "100vh" }}
        >
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter history={history}>
                        <Theme>
                            <Auth0Provider
                                domain={domain}
                                clientId={clientId}
                                authorizationParams={{
                                    redirect_uri: window.location.origin,
                                    audience: audience,

                                }}
                                scope="openid profile email "
                                useRefreshTokens={true}
                                cacheLocation="localstorage"

                            >
                                <Layout />
                            </Auth0Provider>
                        </Theme>
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        </ScrollBar>
    )
}

export default App
