import { combineReducers } from '@reduxjs/toolkit'
import session from './sessionSlice'
import user from './userSlice'
import organization from './organizationSlice'

const reducer = combineReducers({
    session,
    user,
    organization
})

export default reducer
