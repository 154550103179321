import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";
import { Input } from "components/ui";
const SearchBox = ({ globalFilter, setGlobalFilter }) => {
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <div className="flex justify-end">
            <div className="flex items-center mb-4 mr-4">
                <Input
                    value={value || ""}
                    onChange={(e) => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={` Search...`}
                />
            </div>
        </div>
    );
};

export default SearchBox;
