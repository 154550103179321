import React, { useMemo } from 'react'
import {
    useFilters,
    useGlobalFilter,
    // useRowSelect,
    useTable,
    useSortBy,
    usePagination,
} from 'react-table'
// import ActionDropdown from './ActionDropdown'
import { Button, Card, Table } from 'components/ui'

import { fuzzyTextFilterFn } from './util'
// import IndeterminateCheckbox from './IndeterminateCheckBox'
import DataTH from './DataTH'
import DataTBody from './DataTBody'
import DataTablePagination from './DataTablePagination'
import DataTableHeading from './DataTableHeading'
import { FiEdit2, FiMaximize2, FiTrash } from 'react-icons/fi'
import { useSelector } from 'react-redux'

const DataTable = (props) => {
    const {
        columns,
        data = [],
        dataLength,
        sortable = false,
        searchable = true,
        exportable = false,
        scrollContainerRef=null,
        tableHeading = 'Sample Table',
        // isSelected,
        isActionable = true,
        onEditClick = () => console.log('on edit click'),
        onViewClick = () => console.log('on View click'),
        onDeleteClick = () => console.log('on Delete click'),
    } = props

    const role = useSelector((state) => state.auth.user?.role)

    // Let the table remove the filter if the string is empty
    fuzzyTextFilterFn.autoRemove = (val) => !val
    const filterTypes = useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: DataTable.fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    const actionableColumn = {
        id: 'action',
        Header: () => 'Action',
        Cell: ({ row }) => (
            <div className="flex justify-center items-center">
                <Button
                    shape="circle"
                    size="xs"
                    variant="twoTone"
                    color="blue-600"
                    icon={<FiMaximize2 />}
                    onClick={() => onViewClick(row.original)}
                />
                <Button
                    shape="circle"
                    size="xs"
                    variant="twoTone"
                    color="green-600"
                    icon={<FiEdit2 />}
                    className="ml-2"
                    onClick={() => onEditClick(row.original)}
                />
                {
                    ( role?.includes("Admin")) &&
                    <Button
                        shape="circle"
                        size="xs"
                        variant="twoTone"
                        icon={<FiTrash />}
                        className="ml-2"
                        onClick={() => onDeleteClick(row.original.id)}
                    />
                }
            </div>
        ),
    }
    // const selectableColumn = {
    //     id: 'selection',
    //     Header: ({ getToggleAllRowsSelectedProps }) => (
    //         <div className="max-w[10]">
    //             <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
    //         </div>
    //     ),
    //     Cell: ({ row }) => (
    //         <div>
    //             <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    //         </div>
    //     ),
    // }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        allColumns,
        page,
        gotoPage,
        setPageSize,
        // selectedFlatRows,

        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            filterTypes,
            initialState: { pageIndex: 0 },
            manualPagination: false,
        },

        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
        // useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => {
                return isActionable ? [...columns, actionableColumn] : columns
            })
        }
    )

    // console.log('selectedFlatRows', selectedFlatRows)

    // useEffect(() => {
    //     isSelected(selectedFlatRows)
    // }, [isSelected, selectedFlatRows])

    return (
        <Card>
            <Card >
                {/* table heading */}
                <DataTableHeading
                    tableHeading={tableHeading}
                    data={data}
                    exportable={exportable}
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    searchable={searchable}
                    setGlobalFilter={setGlobalFilter}
                    state={state}
                />

                {/* main table  */}
                <div ref={scrollContainerRef} className="overflow-x-auto max-h-[400px] lg:max-h-[400px] xl:max-h-[450px] 2xl:max-h-[60vh]">

                <Table {...getTableProps()} id="table" className="mt-4" oveerflow={false} compact>
                    <DataTH sortable={sortable} headerGroups={headerGroups}  />
                    <DataTBody
                        allColumns={allColumns}
                        getTableBodyProps={getTableBodyProps}
                        page={page}
                        prepareRow={prepareRow}
                    />
                </Table>
                </div>
            </Card>
            {/* pagination for the table */}
            <DataTablePagination
                dataLength={dataLength}
                gotoPage={gotoPage}
                pageIndex={pageIndex}
                setPageSize={setPageSize}
                pageSize={pageSize}
            />
        </Card>
    )
}

export default DataTable
