import DataTable from './DataTable'
import ActionDropdown from './ActionDropdown'
import SearchBox from './SearchBox'
import FilterSearch from './FilterSearch'
import { fuzzyTextFilterFn } from './util'

DataTable.ActionDropdown = ActionDropdown
DataTable.SearchBox = SearchBox
DataTable.fuzzyTextFilterFn = fuzzyTextFilterFn
DataTable.FilterSearch = FilterSearch

export default DataTable
