import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    picture: '',
    name: '',
    given_name: '',
    family_name: '',
    email: '',
    phone_number: '',
    organization: '',
    nickname: '',
    updated_at: '',
    email_verified: false,
    sub: '',
    time_zone:"Asia/Kolkata"
}

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (_, action) => action.payload,
        userLoggedOut: () => initialState,
    },
})

export const { setUser ,userLoggedOut} = userSlice.actions

export default userSlice.reducer
