import React, { memo, useMemo, lazy, Suspense, useEffect } from 'react'
import { Loading } from 'components/shared'
import { useDispatch, useSelector } from 'react-redux'
import {
    LAYOUT_TYPE_CLASSIC,
    LAYOUT_TYPE_MODERN,
    LAYOUT_TYPE_SIMPLE,
    LAYOUT_TYPE_STACKED_SIDE,
    LAYOUT_TYPE_DECKED,
    LAYOUT_TYPE_BLANK,
} from 'constants/theme.constant'
import useDirection from 'utils/hooks/useDirection'
import useLocale from 'utils/hooks/useLocale'
import { useAuth0 } from '@auth0/auth0-react'
import { setUser } from 'store/auth/userSlice'
import { onSignInSuccess } from 'store/auth/sessionSlice'

const layouts = {
    [LAYOUT_TYPE_CLASSIC]: lazy(() => import('./ClassicLayout')),
    [LAYOUT_TYPE_MODERN]: lazy(() => import('./ModernLayout')),
    [LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import('./StackedSideLayout')),
    [LAYOUT_TYPE_SIMPLE]: lazy(() => import('./SimpleLayout')),
    [LAYOUT_TYPE_DECKED]: lazy(() => import('./DeckedLayout')),
    [LAYOUT_TYPE_BLANK]: lazy(() => import('./BlankLayout')),
}

const Layout = () => {
    const layoutType = useSelector((state) => state.theme.layout.type)
    const { isAuthenticated, isLoading, user, getAccessTokenSilently, logout } = useAuth0()
    const userInfo = useSelector((state) => state.auth.user)
    const dispatch = useDispatch()

    useDirection()

    useLocale()

    const AppLayout = useMemo(() => {
        if (
            isAuthenticated
            && (user?.email_verified )
            && (user?.organization)
        ) {
            return layouts[layoutType]
        }
        if (isAuthenticated) {
            return lazy(() => import('./OnboardingLayout'))
        }

        return lazy(() => import('./AuthLayout'))
    }, [isAuthenticated, layoutType,user])

    useEffect(() => {
        const handleTokenRefresh = async () => {
            try {
                if (isAuthenticated) {
                    let accessToken = await getAccessTokenSilently({
                        ignoreCache: true, // Force a fresh token
                    });

                    // Dispatch user details to Redux store
                    dispatch(setUser(userInfo?.sub ? userInfo : user));
                    dispatch(onSignInSuccess(accessToken))
                }

            } catch (error) {
                if (error.error === 'login_required') {
                    // The user needs to log in again
                    logout({ returnTo: window.location.origin });
                } else {
                    console.error('Failed to refresh token:', error);
                }
            }
        };

        handleTokenRefresh();
    }, [isAuthenticated, user, getAccessTokenSilently, dispatch, logout, userInfo]);


    return (
        <Suspense
            fallback={
                <div className="flex flex-auto flex-col h-[100vh]">
                    <Loading loading={true} />
                </div>
            }
        >
            {isLoading ? (
                <div className="flex flex-auto flex-col h-[100vh]">
                    <Loading loading={true} />
                </div>
            ) : (
                <AppLayout />
            )}
        </Suspense>
    )
}

export default memo(Layout)