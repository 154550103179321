import React from "react";

import { Input, Select, Button } from "components/ui";

const FilterSearch = ({ onSearchChange, onOptionChange, options, startFilter }) => {
    return (
        <div className="filter-search  mb-4">
            <p>Filter Search :</p>
            <div className=" text-right flex justify-start item-center py-4">
                <div>
                    <Input placeholder="Filter Search" onChange={onSearchChange} />
                </div>
                <Select placeholder="Please Select" options={options} onChange={onOptionChange} className="w-40" />
                <Button className="ml-4" onClick={startFilter}>
                    Filter
                </Button>
            </div>
        </div>
    );
};

export default FilterSearch;
