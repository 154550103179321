import React from 'react'
import { Drawer } from 'components/ui'

const CDrawer = ({
    title = 'Drawer Title',
    isDrawerOpen,
    onDrawerCloseClick,
    children,
    footerComponent,
}) => {
    return (
        <div>
            <Drawer
                title={title}
                isOpen={isDrawerOpen}
                onClose={onDrawerCloseClick}
                onRequestClose={onDrawerCloseClick}
                footer={footerComponent}
                width={600}
            >
                {children}
            </Drawer>
        </div>
    )
}

export default CDrawer
